<template>
  <div class="tc-user-training">
    <!-- 头部 -->
    <div class="header">
      <div class="header-operation">
        <ul>
          <li @click="isCut(1)">
            <img src="../../../assets/images/oneGameBtn.png" alt />
          </li>
          <li @click="isCut(2)">
            <img src="../../../assets/images/groupGameBtn.png" alt />
          </li>
          <li @click="isCut(3)">
            <img src="../../../assets/images/gameclass.png" alt />
          </li>
        </ul>
      </div>
      <div class="header-tool" v-if="cut == 2">
        <div class="addClass" @click="showGameClassBtn">
          <img src="../../../assets/images/customGameBtn.png" height="40" alt />
        </div>
        <div class="addClass" @click="showAllotGameBox">
          <img src="../../../assets/images/allotGame.png" height="40" alt />
        </div>
        <div class="addClass" @click="queryUserGame">
          <img src="../../../assets/images/queryGame.png" height="40" alt />
        </div>
        <!-- <div class="addClass">
          <img src="../../../assets/images/sortBtn.png" height="40" alt />
        </div>-->
        <!-- <div class="del" @click="delGameClssa">
          <img src="../../../assets/images/del.png" alt />
        </div>-->
        <!-- <div class="refresh" @click="refresh()">
          <img src="../../../assets/images/refresh.png" alt />
        </div>-->
      </div>
      <div class="header-tool" v-if="cut == 1">
        <div class="addClass" @click="showAllotGameBox">
          <img src="../../../assets/images/allotGame.png" height="40" alt />
        </div>
        <div class="addClass" @click="queryUserGame">
          <img src="../../../assets/images/queryGame.png" height="40" alt />
        </div>
        <div class="addClass" @click="showaddGameBox">
          <img src="../../../assets/images/addGame.png" height="39" alt />
        </div>
      </div>
      <div class="header-tool" v-if="cut == 3">
        <div class="addClass" @click="showGameClassBtn">
          <img src="../../../assets/images/addClass.png" height="40" alt />
        </div>
      </div>
    </div>
    <!-- 组合游戏-表格 -->
    <div class="content" v-if="cut == 2">
      <el-table
        max-height="570"
        ref="multipleTable"
        :data="groupTableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange2"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="组合编号"
          width="200"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.account }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="组合名称"
          width="200"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="游戏内容"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="plain" @click="goReport(scope.row.id)"
              >训练</el-button
            > -->
            <!-- <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="goReport(scope.row.id)"
              >旧用户训练</el-button
            > -->
            <!-- <el-divider direction="vertical"></el-divider> -->

            <el-button
              size="mini"
              type="plain"
              @click="showGameClassBtn(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delGameClssa(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 独立游戏 -->
    <div class="content" v-if="cut == 1">
      <!-- 筛选 -->
      <div class="filtrate">
        <div class="header-title">
          <p>筛选</p>
        </div>
        <div class="header-from">
          <el-form
            :inline="true"
            :model="oneQueryForm"
            class="demo-form-inline"
            size="mini"
            ref="oneQueryForm"
          >
            <el-form-item label="游戏名称" prop="name">
              <el-input v-model="oneQueryForm.name"></el-input>
            </el-form-item>
            <el-form-item label="游戏类型" prop="type">
              <el-select
                v-model="oneQueryForm.type"
                placeholder="请选择游戏类型"
              >
                <el-option
                  :label="item.name"
                  :value="item.name"
                  v-for="(item, key) in gameClass"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button plain @click="loadListData()">查询</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button @click="resetForm('oneQueryForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table
        max-height="630"
        ref="multipleTable"
        :data="oneTableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange1"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="游戏名称"
          width="500"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-col :span="5" class="imgGame">
              <img :src="scope.row.img" alt />
            </el-col>
            <div class="titleGame">
              <span>{{ scope.row.title }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="classify_name"
          label="游戏类别"
          width="500"
          align="center"
        >
          <template slot-scope="scope">
            <div class="titleGame">
              <span>{{ scope.row.classify_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="starGame(scope.row)"
              >训练</el-button
            >
            <!-- <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="starGame(scope.row)"
              >旧用户训练</el-button
            > -->
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="showaddGameBox(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delOneGame(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 游戏分类 -->
    <div class="content" v-if="cut == 3">
      <el-table
        max-height="570"
        ref="multipleTable"
        :data="groupTableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange2"
        stripe
        border
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="分类编号"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.account }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="分类名称"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="showGameClassBtn(scope.row)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delGameClssa(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加、编辑 组合游戏模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="showGameClassTitle"
      :visible.sync="classGameBox"
      width="30%"
      center
      @close="reset"
    >
      <el-form ref="classGameForm" :model="classGameForm" label-width="70px">
        <el-form-item :label="cut == 2 ? '组合编号' : '分类编号'">
          <el-input
            v-model.trim="classGameForm.account"
            maxlength="24"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item :label="cut == 2 ? '组合名称' : '分类名称'">
          <el-input
            v-model.trim="classGameForm.name"
            maxlength="32"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="content" v-if="cut == 2">
        <el-table
          ref="multipleTable2"
          :data="oneTableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="addClassChange"
          stripe
          border
          class="tableBox"
          height="250"
        >
          <el-table-column
            type="selection"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="游戏名称"
            style="width: 50%"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="classify_name"
            label="游戏类别"
            style="width: 50%"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.classify_name }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classGameBox = false">取 消</el-button>
        <el-button type="primary" @click="addClassBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配，查询模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="游戏权限设置"
      :visible.sync="allotGameBox"
      width="60%"
      center
      @close="userReset"
    >
      <!-- 筛选 -->
      <div class="filtrate">
        <div class="header-title">
          <p>筛选</p>
        </div>
        <div class="header-from">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            size="mini"
            ref="formInline"
          >
            <el-form-item label="部门" prop="bumen">
              <el-select v-model="formInline.bumen">
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="user">
              <el-input v-model="formInline.user"></el-input>
            </el-form-item>
            <el-form-item label="用户编号" prop="account">
              <el-input v-model="formInline.account"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">查询</el-button>
              <el-button @click="resetForm('formInline')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 表格 -->
      <div class="content">
        <el-table
          :data="userList"
          tooltip-effect="dark"
          style="width: 100%"
          max-height="570"
          ref="multipleTable3"
          @selection-change="handleSelectionChange3"
          stripe
          border
          class="tableBox"
          v-loading="userloading"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="account"
            label="用户编号"
            width="230"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="real_name"
            label="姓名"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="department_name"
            label="部门名称"
            width="250"
            align="center"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.sex | sexFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.age | ageFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="allotGame(scope.row)"
                v-if="!isquery"
                >分配游戏</el-button
              >
              <el-button
                size="mini"
                @click="lookUserGame(scope.row)"
                v-if="isquery"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 页码 -->
      <div class="page" v-if="showUserPage">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="userpageSize"
          :page-sizes="[10, 30, 50, 100, 200, 400]"
          layout="total,sizes, prev, pager, next, jumper"
          :total="usertotal"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allotGameBox = false">关 闭</el-button>
        <el-button type="primary" @click="allotGame" v-if="!isquery"
          >确认分配</el-button
        >
      </span>
    </el-dialog>
    <!-- 查询详情 -->
    <el-dialog
      :close-on-click-modal="false"
      title="用户已有的游戏"
      :visible.sync="queryInfo"
      width="40%"
      center
      @close="userInfoReset"
    >
      <div class="content">
        <el-table
          :data="userInfoData"
          tooltip-effect="dark"
          style="width: 100%"
          max-height="570"
          ref="multipleTable4"
          @selection-change="handleSelectionChange4"
          stripe
          border
          class="tableBox"
          v-loading="userInfoloading"
        >
          <el-table-column
            prop="title"
            label="游戏名称"
            width="500"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="delGmae(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="queryInfo = false">取 消</el-button>
        <el-button type="primary" @click="queryInfo = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加修改-独立游戏 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="addOneGameBoxTitle"
      :visible.sync="addOneGameBox"
      width="800px"
      center
      @close="addGameReset"
    >
      <el-form
        ref="addGameform"
        :model="addGameform"
        label-width="150px"
        size="mini"
      >
        <el-form-item label="游戏名称：">
          <el-input
            v-model.trim="addGameform.title"
            maxlength="24"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏地址：">
          <el-input v-model.trim="addGameform.local_url"></el-input>
        </el-form-item>
        <el-form-item label="游戏类型：">
          <el-select
            v-model="addGameform.game_classify_id"
            placeholder="请选择游戏类型"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, key) in gameClass"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏Logo：">
          <el-upload
            class="avatar-uploader"
            :action="$baseurl + '/file_upload'"
            :show-file-list="false"
            :on-success="fileUploadApi"
            :before-upload="beforeAvatarUpload"
            :data="fileData"
            :with-credentials="true"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述：">
          <el-input
            type="textarea"
            v-model="addGameform.alt"
            rows="4"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOneGameBox = false">取 消</el-button>
        <el-button type="primary" @click="addGame">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  listGame,
  delGame,
  saveGame,
  saveGameClassify,
  listGameClassify,
  distributionsorce,
  deldistributionsorce,
  getdistributionsorce,
  delGameClassify,
} from "@/api/game.js";
import { loadUserData } from "@/api/evaluate.js";
import moment from "moment";
import { loadDepartmentData } from "@/api/comm.js";
import { log } from "util";
export default {
  data() {
    return {
      queryInfo: false,
      allotGameBox: false,
      oneTableData: [],
      oneQueryForm: {
        type: "",
        name: "",
      },
      groupTableData: [],
      // 控制组合、独立页面的切换
      cut: 1,
      // 分配游戏权限模态框-页码数据
      page: 1,
      size: 5,
      // 组合游戏页码数据
      page_class: 1,
      size_class: 15,
      // 游戏分类
      classGameBox: false,
      // 添加游戏分类、组合
      classGameForm: {
        account: "",
        name: "",
        sort: "",
        game_id: [],
        id: 0,
        type: 0, //控制组合、分类
      },
      // 表格勾选项
      multipleSelection1: [],
      multipleSelection2: [],
      multipleSelection3: [],
      userList: [],
      userpage: 1,
      userpageSize: 10,
      usertotal: 0,
      userloading: true,
      formInline: {
        bumen: "",
        user: "",
        account: "",
      },
      departmentList: [],
      showUserPage: true,
      isquery: false,
      userInfoData: [],
      userInfoloading: true,
      showGameClassTitle: "",
      // 添加/修改独立游戏模态框
      addOneGameBox: false,
      addOneGameBoxTitle: "添加新的游戏",
      addGameform: {
        title: "",
        local_url: "",
        head_id: "",
        alt: "",
        game_classify_id: "",
        id: 0,
      },
      imageUrl: "",
      fileData: {
        type: 3,
        attribution_id: 1, // 当前 用户的id
      },
      gameClass: [],
      queryUid: "",
    };
  },
  created() {
    this.loadListData();
    this.loadListClassData();
    this.loadUsers();
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    // 获取游戏分类
    this.addGameClass();
  },
  methods: {
    //获取所有用户
    loadUsers(val) {
      let data = {
        page: this.userpage,
        pagesize: this.userpageSize,
        account: this.formInline.account,
        real_name: this.formInline.user,
        department_id: this.formInline.bumen,
      };
      // 发送数据
      loadUserData(data).then((res) => {
        if (res.code == 400200) {
          this.userList = res.data;
          this.usertotal = res.count;
          this.userloading = false;
        }
      });
    },
    //  独立游戏全选
    handleSelectionChange1(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection1 = p;
    },
    //  组合游戏全选
    handleSelectionChange2(val) {
      this.multipleSelection2 = val;
      let p = [];
      this.multipleSelection2.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection2 = p;
    },
    // 分配游戏-全选
    handleSelectionChange3(val) {
      this.multipleSelection3 = val;
      let p = [];
      this.multipleSelection3.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection3 = p;
      console.log(p);
    },
    handleSelectionChange4() {},
    // 控制组合、独立页面的切换
    isCut(val) {
      if (val === 1) {
        this.cut = 1;
        this.multipleSelection1 = [];
      }
      if (val === 2) {
        this.groupTableData = [];
        this.cut = 2;
        this.classGameForm.type = 0;
        this.multipleSelection2 = [];
        this.loadListClassData();
      }
      if (val == 3) {
        this.groupTableData = [];
        this.classGameForm.type = 1;
        this.cut = 3;
        this.loadListClassData();
      }
    },
    // 独立游戏列表
    loadListData() {
      let data = {
        page: 1,
        size: 9999,
        condition: {
          "%-g.title": this.oneQueryForm.name,
          "%c.name": this.oneQueryForm.type,
        },
      };
      listGame(data).then((res) => {
        this.oneTableData = res.data;
      });
    },
    // 组合游戏包 、 分类
    loadListClassData() {
      // 控制 组合 分类 -type
      let data = {
        page: 1,
        size: 9999,
        condition: {
          "%type": this.classGameForm.type,
        },
      };
      listGameClassify(data).then((res) => {
        if (res.code == 400200) {
          this.groupTableData = res.data;
        }
      });
    },
    //添加、编辑 组合游戏 游戏分类
    showGameClassBtn(row) {
      console.log(row);
      if (this.cut == 2) {
        this.showGameClassTitle = "添加游戏组合";
        if (row.id) {
          let { name, id, type, account, game_id, sort } = row;
          this.classGameForm.account = account;
          this.classGameForm.name = name;
          this.classGameForm.id = id;
          this.$nextTick(() => {
            this.oneTableData.forEach((v) => {
              game_id.split(",").forEach((v2) => {
                if (v.id == v2) {
                  this.$refs["multipleTable2"].toggleRowSelection(v, true);
                }
              });
            });
          });
        }
      } else if (this.cut == 3) {
        this.showGameClassTitle = "添加分类";
        if (row.id) {
          this.showGameClassTitle = "修改分类";
          let { name, id, type, account, game_id, sort } = row;
          this.classGameForm.account = account;
          this.classGameForm.name = name;
          this.classGameForm.game_id = game_id;
          this.classGameForm.id = id;
        }
      }
      this.classGameBox = true;
    },
    // 监听选择的游戏ID
    addClassChange(val) {
      let arr = [];
      val.forEach((v) => {
        arr.push(v.id);
      });
      this.classGameForm.game_id = arr.toString();
    },
    // 添加组合游戏
    addClassBtn() {
      saveGameClassify(this.classGameForm).then((res) => {
        if (res.code == 400200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.classGameForm.id = 0; // 重置id 0为添加
          this.classGameBox = false;
        }
        this.loadListClassData();
      });
    },
    // 跳转游戏页面
    starGame(row) {
      // window.open(row.local_url, '_blank');
      console.log(row);

      var winName = "newWin";
      var awidth = 550;
      var aheight = 850;
      if (row.sign == "idiom") {
        awidth = 1350;
        aheight = 650;
      }
      var atop = (screen.availHeight - aheight) / 2;
      var aleft = (screen.availWidth - awidth) / 2;
      var param0 = "scrollbars=0,status=0,menubar=0,resizable=2,location=0";
      var params =
        "top=" +
        atop +
        ",left=" +
        aleft +
        ",width=" +
        awidth +
        ",height=" +
        aheight +
        "," +
        param0;
      let win = window.open(row.local_url, winName, params);
      win.focus();
    },
    // 重置模态框
    reset() {
      this.classGameForm.game_id = [];
      this.classGameForm.account = "";
      this.classGameForm.id = 0;
      this.classGameForm.name = "";
      if (this.cut == 2) {
        this.$refs.multipleTable2.clearSelection();
      }
    },
    // 显示分配用户模态框
    showAllotGameBox() {
      if (this.cut == 1) {
        if (this.multipleSelection1.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选出，需要分配的游戏",
            type: "warning",
          });
          return;
        }
      } else if (this.cut == 2) {
        if (this.multipleSelection2.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选出，需要分配的游戏组合包",
            type: "warning",
          });
          return;
        }
      }
      this.isquery = false;
      this.allotGameBox = true;
    },
    // 设置当前页面最大显示
    handleSizeChange(val) {
      this.userloading = true;
      this.userpageSize = val;
      this.loadUsers();
    },
    // 页码监听
    handleCurrentChange(val) {
      this.userloading = true;
      this.userpage = val;
      this.loadUsers();
    },
    // 分配游戏 查询
    onSubmit() {
      this.userloading = true;
      this.showUserPage = false;
      this.loadUsers(1);
      this.$nextTick(() => {
        this.showUserPage = true;
      });
    },
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 分配游戏-确认分配
    allotGame(row) {
      let data = {
        uid: "",
        type: 0,
      };
      if (row.id) {
        data.uid = row.id.toString();
      } else {
        if (this.multipleSelection3.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选出，需要分配的用户",
            type: "warning",
          });
          return;
        }
        data.uid = this.multipleSelection3.toString();
      }
      if (this.cut == 1) {
        data.oids = this.multipleSelection1.toString();
      } else if (this.cut == 2) {
        data.class_id = this.multipleSelection2.toString();
      }
      distributionsorce(data).then((res) => {
        if (res.code == 400200) {
          this.$refs.multipleTable3.clearSelection();
          this.$message({
            showClose: true,
            message: "恭喜你，分配游戏成功",
            type: "success",
          });
        }
      });
    },
    // 重置用户分配游戏
    userReset() {
      this.$refs.multipleTable3.clearSelection();
    },
    // 显示 用户列表
    queryUserGame() {
      this.allotGameBox = true;
      this.isquery = true;
    },
    // 查看用户获得的游戏
    lookUserGame(row) {
      this.userInfoloading = true;
      this.queryInfo = true;
      this.queryUid = row.id;
      let data = {
        type: 0,
        uid: row.id,
        page: 0,
        pagesize: 99999,
      };
      getdistributionsorce(data).then((res) => {
        if (res.code == 400200) {
          this.userInfoData = res.data;
          this.userInfoloading = false;
        }
      });
    },
    // 删除用户拥有游戏
    delGmae(row) {
      let data = {
        ids: row.id.toString(),
        uid: this.queryUid,
        type: 0,
      };
      deldistributionsorce(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "恭喜你，删除成功！",
            type: "success",
          });
          let r = this.userInfoData.filter((res, k) => {
            return res.id != row.id;
          });
          this.userInfoData = r;
        }
      });
    },
    // 重置游戏拥有模态框
    userInfoReset() {
      this.userInfoData = [];
    },
    // 删除组、分类
    delGameClssa(row) {
      let id = "";
      if (row.id) {
        id = row.id;
      }
      let data = {
        id,
      };
      delGameClassify(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.loadListClassData();
        }
      });
    },
    // *******************獨立游戲*************************
    // 添加
    addGame() {
      saveGame(this.addGameform).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.addOneGameBox = false;
          this.loadListData();
        }
      });
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      //console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      this.addGameform.head_id = response.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 添加游戏-游戏分类
    addGameClass() {
      let data = {
        page: 1,
        size: 9999,
        condition: {
          "%type": 1,
        },
      };
      listGameClassify(data).then((res) => {
        if (res.code == 400200) {
          this.gameClass = res.data;
        }
      });
    },
    // 关闭添加游戏模态框
    addGameReset() {
      this.addGameform = {
        title: "",
        local_url: "",
        head_id: "",
        alt: "",
        game_classify_id: "",
        id: 0,
      };
      this.imageUrl = "";
    },
    // 显示模态框
    showaddGameBox(row) {
      this.addOneGameBox = true;
      if (row.id) {
        let { id, local_url, title, img, game_classify_id, alt, head_id } = row;
        this.addGameform = {
          id,
          local_url,
          title,
          head_id,
          game_classify_id,
          alt,
        };
        this.imageUrl = img;
      }
    },
    // 删除独立游戏
    delOneGame(row) {
      let data = {
        id: row.id,
      };
      delGame(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.oneTableData = this.oneTableData.filter((v) => {
            return v.id != row.id;
          });
        }
      });
    },
  },
  filters: {
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
    // 年龄过滤器
    ageFilter(val) {
      let text = moment(val, "YYYY-MM-DD").fromNow();
      let age = parseInt(text, 10);
      if (isNaN(age)) {
        age = "0";
      }
      return age;
    },
  },
};
</script>
<style lang="less">
.tc-user-training {
  transition: all 0.5s;
  // 头部
  .header {
    margin: 0 auto;
    display: flex;
    padding-top: 5px;
    justify-content: center;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 150px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      .addClass {
        width: 100px;
        cursor: pointer;
        margin-right: 10px;
      }
      .refresh,
      .del {
        width: 100px;
        cursor: pointer;
        margin-right: 10px;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
    .imgGame {
      width: 140px;
      height: 100px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .titleGame {
      // height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      span {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .el-dialog__wrapper {
    .content {
      position: relative;
      height: calc(~"100% - 220px");
      min-height: 0px;
      margin-top: 20px;
      color: rgb(4, 87, 102);
      th {
        color: rgb(4, 87, 102);
        padding: 0;
        height: 40px;
        line-height: 40px;
        background: rgb(217, 242, 247);
      }
      td {
        padding: 0;
        height: 35px;
        line-height: 35px;
      }
      tr.el-table__row--striped td {
        background: rgb(234, 246, 248);
      }
      .el-button + .el-button {
        margin-left: 0px;
      }
      .el-button--mini {
        padding: 4px 4px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 14px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
      .no {
        color: green;
      }
      .off {
        color: red;
      }
      .el-input__inner {
        width: 50px;
        padding: 5px;
        text-align: center;
      }
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-bottom: 10px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
    }
  }
  // 上傳
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 160px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 160px;
    height: 120px;
    display: block;
  }
}
</style>